import { createGlobalStyle } from 'styled-components';
import { bodyBackgroundColor } from 'utils/theme';
import { variables } from 'components/theme';

const GlobalStyle = createGlobalStyle`
  body.fontLoaded {
    font-family: 'Open Sans', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu,
    Cantarell, 'Helvetica Neue', Helvetica, Arial;
  }

  html,
  body {
    height: 100vh;
    width: 100%;
    line-height: 1.9;
    margin: 0;
    padding: 0;
    font-size: 14px;
    scroll-behavior: smooth;
    background: ${bodyBackgroundColor};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .ReactModal__Body--open {
    position: static;
    /* overflow-y: scroll; */
  }

  .ReactModal__Html--open {
    overflow-y: hidden;
  }

  .ReactModalPortal {
    position: fixed;
    z-index: 500;
  }
  };

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  };

  :focus {
    outline: none;
  };

  *::selection {
    background: #faea28 !important;
    color: #31383E;
  }

  h1, h2, h3, h4, h5, h6, label {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
  }

  h1 {
    font-size: 2.25em;
  }

  h2 {
    font-size: 1.875em;
  }

  h3 {
    font-size: 1.5em;
    line-height: 26px;
  }

  h4 {
    font-size: 18px;
  }

  h5 {
    font-size: 1em;
  }

  h6 {
    font-size: 0.875em;
    margin-bottom: 0;
  }

  strong {
    color: #31383e;
  }

  a {
    cursor: pointer;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px;
  }

  button {
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    font-family: 'Overpass Mono', monospace;
    font-weight: 600;
    cursor: pointer;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  input {
    font-family: 'Open Sans', sans-serif;
  }

  input:-internal-autofill-selected {
    background-color: rgba(250, 234, 41, .1) !important;
    color: #31383E !important;
  }

  /* Change Autocomplete styles in Chrome*/
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: 1px solid ${variables.colors.gray7};
    -webkit-text-fill-color: ${variables.colors.grey4};
    box-shadow: 0 0 0px 1000px rgba(250, 234, 41, 0.05) inset;
    transition: background-color 3000s ease-in-out 0s;
  }

  o:p {
    display: none !important;
  }

`;

export default GlobalStyle;
