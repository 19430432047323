import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';

const MetaHelmet = props => {
  const { title, description, image, url } = props;
  const fullUrl = `https://mookh.com${url}`;
  return (
    <Helmet titleTemplate="MOOKH: %s">
      <title>{title}</title>
      {/* Search Engine */}
      <meta name="description" content={description} />
      <meta name="image" content={image} />
      {/* Schema.org for Google */}
      <meta itemprop="name" content={title} />
      <meta itemprop="description" content={description} />
      <meta itemprop="image" content={image} />
      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image:src" content={image} />
      <meta name="twitter:site" content="@MookhAfrica" />
      <meta name="twitter:creator" content="@MookhAfrica" />
      {/* Open Graph general (Facebook, Pinterest & Google+) */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:url" content={fullUrl} />
      <meta property="og:site_name" content="MOOKH Africa" />
      <meta property="og:type" content="website" />
    </Helmet>
  );
};

MetaHelmet.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  url: PropTypes.string
};

MetaHelmet.defaultProps = {
  title: 'Making Commerce Social',
  description:
    'A social-ecommerce platform that allows sellers to quickly and easily set up online stores with installed payment integration - MOOKH.',
  image: '%PUBLIC_URL%/mookh-cover.jpg',
  url: 'https://mookh.com'
};

export default MetaHelmet;
