export const BASE_URL = 'https://api.mookh.com';
export const SENDGRID_KEY =
  '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';
export const STORE_FEATURED = '/stores/featured';
export const STORE_EVENTS = 'stores/event/public/?store_fk__slug=';
export const STORE_MUSIC = 'digital_content/album/public/?store__slug=';
export const FACEBOOK_PIXEL_ID = '2147425078816828';
export const STAFF_PICK_ITEMS = '/stores/event/public/?is_staff_pic=true';
export const FEATURED_ITEMS = '/stores/event/public/?is_featured=true';
export const EVENTS_ENDPOINT = '/stores/event/public/';
export const GET_EVENT = '/stores/event/public/slug';
export const DIGITAL_CONTENT_ITEM = '/digital_content/album/public/slug';
export const DIGITAL_CONTENT_ITEM_STORE = '/digital_content/album/public';
export const DIGITAL_ENDPOINT = '/digital_content/album/public';
export const DIGITAL_CONTENT_MUSIC_CATEGORIES =
  'digital_content/category/?minimum_media_count=1';
export const SEARCH = '/search';
export const ORDERS =
  '/stores/orderitems/?page_size=15&page=1&client=buyer&order_status=COMPLETE';
export const SHARE = '/stores/reservations/?page_size=8&page=1&client=buyer';
export const LOCATION = '/suggest_locale';
export const TICKET_ORDER = '/stores/reservations_data/';
export const COMPLETE_ORDER = '/stores/orders/order_status/?order_number=';
export const LIBRARY_ITEMS = '/stores/orderitems/?music=all&client=buyer';
export const PROMOTIONS_URL = '/stores/order_bundles';
export const USER_STREAMS = '/api/streaming/subscriptions/';
